.form-container{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15vh;
    margin-bottom: 15vh;
}
.form{
    height: auto;
    width: 60%;
    background-color: white;
    border-radius: 38px;
    box-shadow: 0px 0px 157px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form>h2{
    color: black;
    font-size: 2.5rem;
}
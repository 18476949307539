@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500;900&display=swap);
body{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: white;
  overflow-x: hidden;
  height: 100%;
  
}
::selection {
  color: white;
  background: #A8DADC;
}

a{
  text-decoration: none;
}


.glass{
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: rgba(199, 199, 199, 0.01);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  z-index: -1;
}
.blobSvg{
  position: fixed;
width: 948.8px;
height: 813.75px;
left: -300px;
top: -200px;
z-index: -3;
fill: #91F2DB;
transform: rotate(90deg);
}
.blobSvg2{
  position: fixed;
width: 948.8px;
height: 300.75px;
right: -300px;
top: 70vh;
z-index: -3;
fill: #91F2DB;
transform: rotate(90deg);
}
.img{
  position: absolute;
  transform: scale(0.8);
  overflow: hidden;
left: 50vw;
top: -13vh;
}
.appBar{
  width: 70vw;
  position: absolute;
  background-color: white;
  height: 60px;
  top: 40px;
  margin-left: calc(15vw - 20px);
  border-radius: 38px;
  box-shadow: 0px 0px 157px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  transition: all 200ms ease-in-out;
}
.logo>a , .mobile-logo>a, .footer-logo>a {
  font-weight: 900;
  color: #A8DADC;
  font-size: 1.5rem;
}
.mobile-logo{
  width: 100%;
  display: flex;
  justify-content: center;
  height: 10vh;
  align-items: center;
  border-bottom: 2px solid #91F2DB;
  margin-bottom: 20px;
}
.MuiDrawer-paperAnchorLeft{
  width: 40vw;
  max-width: 350px;
} 

.link{
  text-decoration: none;
  color: black;
  font-weight: 300;
  padding: 5px;
  transition: all 150ms ease-in-out;
  animation-delay: 200ms;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}
.burger{
  position: absolute;
  left: 20px;
  top: auto;
  height:49%;
  color: #17989c;
  width: auto;
  visibility: hidden;
  transform: scale(0);
}
.mobile-links{
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  
}
.links{
  overflow: hidden;

}
.link:hover{
  font-size: 1.1rem;
  color: #91F2DB;
}

.mobile-appBar{
  visibility: hidden;
}
.content1{
  height: auto;
  margin-top: 30vh;
  margin-bottom: 25vh;
  width: 40vw;
  margin-left: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.title-container>h1{
  font-weight: 900;
  margin: 0;
  line-height: 45px;
  color: #192e4d;
  font-size: 4vw;
 
}
.title-container>h2{
  font-weight: 300;
  margin: 0;
  color: #457B9D;
  font-size: 2rem;
}
.content1 p{
  color: #192e4d;
  font-size: 1.3rem;
  display: block; 
  width: 50vw;
 
  margin-bottom: 30px;
}
.caroussel{
  height: auto;
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  overflow: scroll;
  padding: 40px 40px;
  margin: 0px 30px;
  border-radius: 30px;
  /*background-color: rgba(255, 255, 255, 0.2);*/
  flex-wrap:wrap;
  justify-content: center;
  /*box-shadow: 0px 0px 90px 2px rgba(0, 0, 0, 0.05);*/
}
.content2{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.card{
  height: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
  aspect-ratio: 0.6;
  background: #FFFFFF;
  box-shadow: 0px 0px 90px 2px rgba(0, 0, 0, 0.05);
border-radius: 81px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: black;
padding: 20px;
text-align: center;
transition: all 100ms ease-in-out;
}
.card:hover{
  transform: scale(1.03);
  box-shadow: 0px 0px 180px 2px rgba(0, 0, 0, 0.09);
  cursor: pointer;
}
.card>h2{
  font-size: 2rem;
  font-weight: 900;
}
.card>p{
  height: 40%;
  font-size: 1.2rem;
}
.card-icon{
  height: 7vw;
  width: 7vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icon>svg{
  height: 7vw;
  width: 7vw;
}

.contact-button{
  height: 5vw;
  min-height: 80px;
  min-width: 240px;
  width: 15vw;
  background: linear-gradient(95.76deg, #20BDFF 0%, #91F2DB 77.81%);
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  transition: all 100ms ease-in-out;
}
.contact-button:hover{
  transform: scale(1.05);
  box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.footer{
  width: 100%;
  height: 400px;
  bottom: 0;
  background-color: #A8DADC;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-container:nth-of-type(1){
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.footer-container{
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: start;
  align-items: center;
}
.quick-links>h2 {
  font-size: 1rem; 
}
.divider{
  height: 90%;
  width: 1px;
  background-color: white;
}
.footer-logo>a{
  font-size: 200%;
  color: white;

}
.footer-links{
  display: flex;
  flex-direction: column;
  
}
.footer-links>a{
  color: white;
}


@media screen and (max-width: 1000px) {
  .links{
    visibility: hidden;
    width: 0;
  }
  .content2{
    flex-direction: column;
  }
  .card{
    min-height: 400px;
    aspect-ratio: 0.6;
  }
  .links>a{
    opacity: 0;
  }
  .appBar{
    justify-content: center;
  }
  .blobSvg, .blobSvg2{
    width: 0;
    top: 0;
    left: 0;
  }
  .burger{
    position: absolute;
    visibility: visible;
    transform: scale(1);
  }
  .burger:hover{
    cursor: pointer;
  }
  .img{
    visibility: hidden;
    transform: scale(0);
  }
  .content1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top: 25vh;
    width: 70vw;
    margin-left: 15vw;
    text-align: center;
  }
  .content2{
    height: auto;
    margin-bottom: 50px
  }
  .title-container>h1{
    font-size: 2.2rem;
    line-height: 50px;
  }
  .title-container>h2{
    font-size: 1.8rem;
  }
  .content1 p {
    font-size: 0.9rem;
  }
  .contact-button{
    transform: scale(1);
  }
  .contact-button:hover{
    transform: scale(1.05);
  }
 
  .footer{
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .footer-container:nth-of-type(1){
    justify-content: center;
  }
  .footer-logo>a{
    font-size: 150%;
    color: white;
    text-align: center;
  }
  
}
.products-container{
    width: 80vw;
    height: auto;
    /*min-height: 100vh;*/
    margin-top: 20vh;
    margin-left: 10vw;
    margin-bottom: 20vh;
    color: black;
    padding: 10px;
}
.products-header-title{
font-size: 2.5rem;
}
.MuiGrid-item{
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-card{
    width: 25vw;
    /*max-width: 350px;
    min-width: 250px;
    max-height: 350px;
    min-height: calc(25vw+15px);*/
    background-color: white;
    transition: 100ms all ease-out;
    border-radius: 24px;
  box-shadow: 0px 0px 157px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 0.6;
  
  padding: 20px;
  margin: 20px;
}
.product-card:hover{
    cursor: pointer;
    transform: scale(1.05);
}
.product-image{
    height: 40%;
    width: 80%;
    border-radius: 30px;
    max-width: 320px;
    min-width: 250px;
    min-height: 150px;
}
.product-image>img{
    object-fit: contain;
    height: 100%;
    width: 100%;
    border-radius: 30px;
}
.product-description{
    color: rgb(129, 129, 129);
    padding-left: 15px;
    height: 25%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-title{
    margin-top: 20px;
    width: 100%;
    padding-left: 15px;
    font-weight: 900;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    line-clamp: 1;
    display: flex;
    align-items: flex-start;
    justify-content: start;
}
.product-page{
    height: auto;
    width: 80vw;
    max-height: 80vh;
    overflow: scroll;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
.product-image-page{
    padding-top: 10px;
    height: 40%;
    width: 80%;
}
.product-image-page>img{
    object-fit: contain;
    height: 100%;
    width: 100%;
    border-radius: 30px;
}
.product-title-page{
    font-size: 1.5rem;
    color: black;
    font-size: bold;
    width: 80%;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    margin-top: 20px;
}
.close-modal{
    position: absolute;
    top: 20px;
    right: 20px;
    color: rgb(129, 129, 129);
    font-size: 1.3rem;
}
.close-modal:hover{
    cursor: pointer;
}
.product-description-page{
    font-weight: 300;
    width: 80%;
    color: rgb(112, 112, 112);
    height: 50%;
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 1000px) {
.product-card{
    margin-top: 20px;
    margin-bottom: 20px;
    aspect-ratio: 0.7;
    width: 50vw;
}

}
.form-container{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15vh;
    margin-bottom: 15vh;
}
.form{
    height: auto;
    width: 60%;
    background-color: white;
    border-radius: 38px;
    box-shadow: 0px 0px 157px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form>h2{
    color: black;
    font-size: 2.5rem;
}

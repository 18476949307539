.products-container{
    width: 80vw;
    height: auto;
    /*min-height: 100vh;*/
    margin-top: 20vh;
    margin-left: 10vw;
    margin-bottom: 20vh;
    color: black;
    padding: 10px;
}
.products-header-title{
font-size: 2.5rem;
}
.MuiGrid-item{
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-card{
    width: 25vw;
    /*max-width: 350px;
    min-width: 250px;
    max-height: 350px;
    min-height: calc(25vw+15px);*/
    background-color: white;
    transition: 100ms all ease-out;
    border-radius: 24px;
  box-shadow: 0px 0px 157px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 0.6;
  
  padding: 20px;
  margin: 20px;
}
.product-card:hover{
    cursor: pointer;
    transform: scale(1.05);
}
.product-image{
    height: 40%;
    width: 80%;
    border-radius: 30px;
    max-width: 320px;
    min-width: 250px;
    min-height: 150px;
}
.product-image>img{
    object-fit: contain;
    height: 100%;
    width: 100%;
    border-radius: 30px;
}
.product-description{
    color: rgb(129, 129, 129);
    padding-left: 15px;
    height: 25%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-title{
    margin-top: 20px;
    width: 100%;
    padding-left: 15px;
    font-weight: 900;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    line-clamp: 1;
    display: flex;
    align-items: flex-start;
    justify-content: start;
}
.product-page{
    height: auto;
    width: 80vw;
    max-height: 80vh;
    overflow: scroll;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
.product-image-page{
    padding-top: 10px;
    height: 40%;
    width: 80%;
}
.product-image-page>img{
    object-fit: contain;
    height: 100%;
    width: 100%;
    border-radius: 30px;
}
.product-title-page{
    font-size: 1.5rem;
    color: black;
    font-size: bold;
    width: 80%;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    margin-top: 20px;
}
.close-modal{
    position: absolute;
    top: 20px;
    right: 20px;
    color: rgb(129, 129, 129);
    font-size: 1.3rem;
}
.close-modal:hover{
    cursor: pointer;
}
.product-description-page{
    font-weight: 300;
    width: 80%;
    color: rgb(112, 112, 112);
    height: 50%;
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 1000px) {
.product-card{
    margin-top: 20px;
    margin-bottom: 20px;
    aspect-ratio: 0.7;
    width: 50vw;
}

}